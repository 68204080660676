import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import merge from 'lodash/merge';

import ChooserRadioButton from 'common/components/ChooserRadioButton';
import Checkbox from 'common/components/Checkbox';
import { TextArea } from 'common/components/Forms';

interface ChooserRadioButtonDemoState {
  children: string;
  disabled: boolean;
  label: string;
  hoverMessage: string;
  selected: boolean;
}

class ChooserRadioButtonDemo extends Component<any, ChooserRadioButtonDemoState> {
  constructor(props: any, state: ChooserRadioButtonDemoState) {
    super(props, state);
    this.state = {
      children:
        '<div>Click me to select/unselect!<br/>This is where the children are rendered. Use it for descriptions.</div>',
      disabled: false,
      label: '<div>This is where the label is rendered! Use it for any other notes.</div>',
      hoverMessage: 'hoverMessage aka title attribute',
      selected: false
    };
  }

  renderBoolField = (field: string) => {
    const onChange = () => {
      // @ts-ignore unsurprisingly, typescript is not happy about dynamic field setting
      this.setState({
        [field]: !this.state[field]
      });
    };
    return (
      <form>
        <Checkbox id={`${field}-checkbox`} checked={this.state[field]} onChange={onChange}>
          {field}
        </Checkbox>
      </form>
    );
  };

  renderTextAreaField = (field: string, type: string) => {
    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      // @ts-ignore unsurprisingly, typescript is not happy about dynamic field setting
      this.setState({
        [field]: e.target.value
      });
    };
    return (
      <TextArea label={`${field}: ${type}`} name={field} value={this.state[field]} onChange={onChange} />
    );
  };

  renderTextAsHtml = (text: string) => {
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
  };

  render() {
    const { children, disabled, label, hoverMessage, selected } = this.state;

    const chooserRadioButtonProps = {
      disabled,
      label: this.renderTextAsHtml(label),
      hoverMessage,
      onSelection: () => this.setState({ selected: !selected }),
      selected
    };

    // JSX can't be json serialized, so just show the string version in the example code
    const jsonSafeProps = merge({}, chooserRadioButtonProps, { label });

    /* eslint-disable @typescript-eslint/indent */
    //extra spaces here look bad in the demo
    const code = `const props = ${JSON.stringify(jsonSafeProps, null, 2)};
return (
  <ChooserRadioButton {...props}>
    ${children}
  </ChooserRadioButton>
);`;
    /* eslint-enable @typescript-eslint/indent */

    return (
      <div>
        <div>
          <h5>Props</h5>
          {this.renderTextAreaField('children', 'html')}
          {this.renderTextAreaField('label', 'html')}
          {this.renderTextAreaField('hoverMessage', 'string')}
          {this.renderBoolField('disabled')}
        </div>
        <div className="styleguide-example">
          <ChooserRadioButton {...chooserRadioButtonProps}>
            {this.renderTextAsHtml(children)}
          </ChooserRadioButton>
        </div>
        <div className="styleguide-code-example">
          <pre>{code}</pre>
        </div>
      </div>
    );
  }
}

window.onload = () => {
  ReactDOM.render(React.createElement(ChooserRadioButtonDemo), document.getElementById('component-demo'));
};
